/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
    var Sage = {
// All pages
        'common': {
            init: function () {
// JavaScript to be fired on all pages

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                var template_path = js_custom_object.template_path;
                // initialise plugin
                $('#site-navigation ul#primary-menu').superfish();
                //$('#site-navigation ul').slicknav();
                $('#hamburger').click(function () { // Capture responsive menu button click
                    // Show/hide menu
                    $('#primary-menu').slideToggle();
                });

                //Show search form on click of icon
                $('.searchicon').on('click', function () {
                    $(this).hide();
                    $('header .searchform').show();
                    setTimeout(function () {
                        $('header .search-field').css('width', '150px');
                        $('header .search-field').css('padding', '5px');
                    }, 10);
                });
                $('.search-submit').on('click', function (e) {
                    if ($('header .search-field').val() === '') {
                        $('header .search-field').css('width', '0px');
                        $('header .search-field').css('padding', '0');
                        setTimeout(function () {
                            $('header .searchform').toggle();
                            $('header .searchicon').show();
                        }, 80);
                        e.preventDefault();
                    }
                });
                if ($('body').hasClass('search')) {
                    $('header .searchform').show();
                    $('header .search-field').css('width', '150px');
                    $('header .search-field').css('padding', '5px');
                    $('header .searchicon').hide();
                }
                $(".content-area .search-field").attr('required', 'true');

                //sticky header animation on scroll
                $(window).scroll(function () {
                    if ($(this).scrollTop() > 1) {
                        $('header').addClass("smaller");
                        $('.main-content-wrapper').addClass("header-small")
                    }
                    else {
                        $('header').removeClass("smaller");
                        $('.main-content-wrapper').removeClass("header-small")
                    }
                });

                //Spec. Detail Page slider init.
                $('#slider').flexslider({
                    animation: "slide",
                    controlNav: false,
                    animationLoop: false,
                    slideshow: false,
                    itemMargin: 5
                });

                //matchheight init for grid same height for all post list
                /*$('.matchheight').matchHeight({
                    byRow: false
                });*/


                //add active class to menu if its single page of journal or formation
                if ($('body').hasClass('single-post')) {
                    $('.journal-menu-class').addClass('current-menu-item');
                }
                if ($('body').hasClass('single-spectacles')) {
                    $('.spec-menu-class').addClass('current-menu-item');
                }
                if ($('body').hasClass('single-formation')) {
                    $('.formation-menu-class').addClass('current-menu-item');
                }
                //Scroll to section
                function goToByScroll(id) {
                    // Scroll
                    $('html,body').animate({
                        scrollTop: $("#" + id).offset().top - 160},
                    'slow');
                }
                //Archive page - scroll to section of specific year
                $(".sidebar-category li a").click(function (e) {
                    // Prevent a page reload when a link is pressed
                    e.preventDefault();
                    // Call the scroll function
                    $(".sidebar-category li a").removeClass('active');
                    $(this).addClass('active');
                    goToByScroll($(this).attr("id"));
                    if ($('.open-archieves').css('display') == 'block') {
                        $(".sidebar-category").slideToggle();
                    } else {
                        $(".sidebar-category").show();
                    }
                });

                //Scroll to top on click of up-arrow
                $(".scroll-to-top").click(function (e) {
                    // Prevent a page reload when a link is pressed
                    e.preventDefault();
                    goToByScroll($(this).attr("attr-target"));
                });

                $('.scroll-to-top').hide();
                $(window).scroll(function () {
                    if ($(this).scrollTop() >= 150) {
                        $('.scroll-to-top').fadeIn(500);
                        if ($('.open-archieves').css('display') == 'block') {
                            $('.sidebar-for-mobile').fadeIn(500);
                        }
                    } else {
                        $('.scroll-to-top').fadeOut(500);
                        if ($('.open-archieves').css('display') == 'block') {
                            $('.sidebar-for-mobile').fadeOut(500);
                        }
                    }
                });
                //Sticky sidebar with categories
                //$('.sidebar-category').scrollToFixed({marginTop: 120});

                //Isotope - Blogs and gallery
                var $container = jQuery('.isotop-wrap');
                $container.imagesLoaded(function () {
                    $container.isotope({
                        itemSelector: '.isotop-Item'
                    });
                });

                $container.infinitescroll({
                    navSelector: '#page_nav', // selector for the paged navigation 
                    nextSelector: '#page_nav a', // selector for the NEXT link (to page 2)
                    itemSelector: '.isotop-Item', // selector for all items you'll retrieve
                    loading: {
                        finishedMsg: 'Terminé.',
                        msgText: 'Chargement',
                        img: template_path + '/dist/images/ajax-loader.gif'
                    }
                },
                // call Isotope as a callback
                function (newElements) {
                    //jQuery('.isotop-wrap').isotope('appended', jQuery(newElements));
                    // hide new items while they are loading
                    var $newElems = jQuery(newElements).css({opacity: 0});
                    // ensure that images load before adding to masonry layout
                    $newElems.imagesLoaded(function () {
                        // show elems now they're ready
                        $newElems.animate({opacity: 1});
                        $container.isotope('appended', $newElems, true);
                        initPrettyphoto();
                    });
                });

                //Prettyphoto init - call on document on ready and when isotope new elements added
                function initPrettyphoto() {
                    $("a[rel^='prettyPhoto']").prettyPhoto({
                        allow_expand: false,
                        deeplinking: false,
                        overlay_gallery_max: 100,
                        social_tools: '',
                        overlay_gallery: false,
                        changepicturecallback: function () {
                            var stringText = $('.pp_description').text();
                            $('.ppt').html(stringText);
                            $('.pp_nav,.pp_description').hide();
                        }
                    });
                }
                initPrettyphoto();

                //plus-minus seats
                $(document).on('click', '.quantityBtns a', function (e) {
                    var currentusercan = js_custom_object.currentusercan;
                    e.preventDefault();
                    var newQuantity = parseInt($('.quantityBtns span').html());

                    if ($(this).hasClass("up"))
                        newQuantity++;
                    else
                        newQuantity--;
                    // stop if quantity exceeds the limit
                    if (newQuantity < 1)
                        return;
                    if (currentusercan != 'admin') {
                        if (newQuantity > 10)
                            return;
                    }
                    if (newQuantity > parseInt($('.spotsleft span').html()))
                        return;
                    $('.quantityBtns span').html(newQuantity);
                    $('input[name="quantity"]').val(newQuantity);
                });

                //Get the available seats on based of select show dates
                $(document).on('change', '#tickets_dates', function (e) {
                    $('input[name="quantity"]').val("1");
                    $('.quantityBtns span').html("1");
                    var tickets_dates = $("#tickets_dates option:selected").val();
                    var postid = $('#post_id_hidden').val();
                    $.ajax({
                        data: {action: 'get_available_seats', "tickets_dates": tickets_dates, "postid": postid},
                        type: 'POST',
                        url: ajaxurl,
                        beforeSend: function () {
                            //jQuery('.ajax_loader_div').show();
                        },
                        success: function (data) {
                            $('.spotsleft span').html(data);
                        }
                    });
                });
                //open popup and fetch dates of show
                $(".event-modal-open").on('click', function () {
                    $('.buy-form').hide();
                    $('.date-listing').show();
                    var hidden_postid = $('#post_id_hidden').val();
                    var postid = $(this).attr('data-attr');
                    $.ajax({
                        data: {action: 'popup_event_listing', "postid": postid},
                        type: 'POST',
                        url: ajaxurl,
                        beforeSend: function () {
                            //if (hidden_postid !== postid) {
                            $('#modal_wrap').html("");
                            $('.ajax_loader_div').show();
                            // }
                        },
                        success: function (data) {
                            // if (hidden_postid !== postid) {
                            $('.ajax_loader_div').hide();
                            $('#modal_wrap').html(data);
                            $('#eventlist_modal').modal('show');
                            //}
                            $('#post_id_hidden').val(postid);
                        }
                    });
                });
                //show booking form on click of reserve button
                $(document).on('click', '.reservebutton', function (e) {
                    $('.date-listing').fadeOut(200);
                    var click_date = $(this).attr('date-time-attr');
                    var seats = $(this).attr('data-seats');
                    setTimeout(function () {
                        $('.buy-form').fadeIn(500);
                    }, 201);
                    setTimeout(function () {
                        $("#tickets_dates").val(click_date);
                        $('.spotsleft span').html(seats);
                    }, 250);

                });
                function mobileArchiveSidebar() {
                    if ($('.open-archieves').css('display') == 'block') {
                        $(".sidebar-category").hide();
                    } else {
                        $(".sidebar-category").show();
                    }
                }
                mobileArchiveSidebar();
                $(window).resize(function () {
                    mobileArchiveSidebar();
                    setTimeout(function () {
                        $('.matchheight').matchHeight({
                            byRow: false
                        });
                    }, 150);
                });
                //Mobile Archieve page

                $(document).on('click', '.open-archieves', function (e) {
                    $("ul.sidebar-category").slideToggle();
                });
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
    
    // before load
    $('.post-grid-with-hover-effect').imagesLoaded( {
    },
    function() {
        
        /*$('.post-grid-with-hover-effect').removeClass('hidden');
        $('.center-block').hide();*/
        setTimeout(function () {
            $('.post-grid-with-hover-effect').removeClass('hidden');
            $('.center-block').hide();
        }, 1000);
        setTimeout(function () {
            $('.matchheight').matchHeight({
            byRow: false
        });
        }, 1100);
    }
  );
     
})(jQuery); // Fully reference jQuery after this point.